@import 'src/resources/styles';

.id {
  @include style-id-mark-in-text();
}

.wrap {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;

  .wrapAnnProps {
    width: 100%;
    padding: 32px 0;
  
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
}