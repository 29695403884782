@import 'src/resources/styles';

.wrap {
  width: 300px;
  padding: 12px 6px;
  position: relative;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;

  border: 2px solid #e5e5e5;
  border-radius: 12px;
  text-decoration: none;

  .id {
    @include style-id-mark-absolute();
    opacity: 0;
  }

  &:hover {
    background: #f2f2f2;

    .id {
      opacity: 1;
    }
  }

  .img {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .title {
      @include font (14px, 700, 0.05em);
      line-height: 120%;
      text-align: left;
      text-transform: uppercase;
    }

    .amount {
      padding: 6px 12px;

      position: absolute;
      right: 8px;
      bottom: 8px;

      @include font (12px, 500, 0.05em);
      text-transform: uppercase;
      color: white;
      background-color: rgb(73, 129, 206);
      border-radius: 12px;
    }
  }
}