@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@mixin font ($size, $weight, $spacing) {
  font-family: 'Manrope', sans-serif;
  font-size: $size;
  line-height: 100%;
  font-weight: $weight;
  letter-spacing: $spacing;
  color: #333;
}

@mixin icon ($size, $color) {
  min-width: $size;
  width: $size;
  min-height: $size;
  height: $size;

  path {
    fill: $color;
  }
}

$color-accent: #4981ce;
$color-accent-2: #537391;
$color-accent-3: #e29032;
$color-cancel: #e16853;
$color-success: #41c566;

$color-success-70: rgba($color-success, 0.7);
$color-success-30: rgba($color-success, 0.3);


// OFTEN STYLES

@mixin style-id-mark-absolute () {
  position: absolute;
  left: -10px;
  top: -6px;

  display: block;
  padding: 4px 8px;
  background: $color-accent;
  border-radius: 18px;

  @include font (10px, 500, 0);
  color: white;

  transition: all 0.3s ease;
}

@mixin style-id-mark-in-text () {
  display: block;
  padding: 4px 8px;
  margin-right: 12px;
  background: $color-accent;
  border-radius: 18px;
  @include font (14px, 500, 0);
  color: white;
}