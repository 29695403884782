@import 'src/resources/styles';

.input {
  width: 100%;
  height: 40px;
  padding: 0 24px;

  border: 1px solid #ddd;
  border-radius: 12px;

  @include font (16px, 500, 0.05em);
  color: #444;

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    font-weight: 500;
    color: #ccc;
  }
}

.label {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    display: inline-block;
    padding-left: 12px;
    @include font (12px, 600, 0.05em);
    text-transform: uppercase;
    text-align: left;
  }
}