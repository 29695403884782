@import 'src/resources/styles';

.wrap {
  width: 100%;
  margin: 24px 0;
  padding: 12px;

  display: flex;
  align-items: center;
  gap: 32px;

  background: #F8F8F8;
  border-radius: 12px;

  .number {
    @include font (70px, 700, 0);
    color: #ddd;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 6px;
    
    .title {
      @include font (20px, 700, 0.1em);
      text-transform: uppercase;
    }

    .subtitle {
      @include font (16px, 500, 0.05em);
      color: #bbb;
    }
  }
}