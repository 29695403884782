@import 'src/resources/styles';

.wrap {
  width: 100%;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  .title {
    padding-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .notComment {
    padding: 8px 24px;
    display: flex;
    align-items: center;
    gap: 16px;

    background: #eee;
    border-radius: 12px;

    .icon {
      @include icon (20px, #777);
    }

    p {
      @include font (14px, 600, 0.05em);
      color: #777;
    }
  }

  .info {
    margin: 8px 0;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    border-left: 3px solid $color-success-70;

    p {
      @include font (14px, 700, 0.05em);
    }

    .comment {
      @include font (14px, 400, 0.05em);
      line-height: 110%;
    }
  }

  .fieldgroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  .textareagroup {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    textarea {
      width: 100%;
      height: 180px;
      padding: 16px 24px;
    
      border: 1px solid #ddd;
      border-radius: 12px;
    
      @include font (16px, 500, 0.05em);
      color: #444;
    }
    
    p {
      display: inline-block;
      padding-left: 12px;
      @include font (12px, 600, 0.05em);
      text-transform: uppercase;
      text-align: left;
    }
  }

  .buttongroup {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
  }
}