@import 'src/resources/styles';

.wrap {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .title {
    @include font (18px, 600, 0.1em);
  }

  .form {
    width: 400px;
    height: auto;

    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .icon {
    padding-right: 12px;
  }

  .input {
    padding: 8px 12px;
  }

  .button {
    width: 100%;
    height: 32px;
  }
}
