@import 'src/resources/styles';

// -------------  LOADER  ------------------

.ldsEllipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 24px;
}

.ldsEllipsis div {
  position: absolute;
  top: 8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ldsEllipsis div:nth-child(1) {
  left: 4px;
  animation: ldsEllipsis1 0.6s infinite;
}

.ldsEllipsis div:nth-child(2) {
  left: 4px;
  animation: ldsEllipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(3) {
  left: 16px;
  animation: ldsEllipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(4) {
  left: 28px;
  animation: ldsEllipsis3 0.6s infinite;
}

@keyframes ldsEllipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ldsEllipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes ldsEllipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}


// ---------------------- BUTTON ----------------------

.btn {
  height: 24px;
  padding: 0 12px;
  border: none;
  border-radius: 12px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  @include font (14px, 600, 0.05em);
  text-transform: uppercase;

  &:disabled {
    background: #bbb !important;
    color: #777 !important;
  }
}

.default {
  background: white;
  border: 1px solid #ccc;
  color: #888;

  .ldsEllipsis div {
    background: #888;
  }
}

.action {
  background: $color-accent;
  color: white;
}

.cancel {
  background: $color-cancel;
  color: white;
}

.success {
  background: $color-success;
  color: white;
}