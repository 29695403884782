@import 'src/resources/styles';

.container {
  position: relative;

  .bar {
    // position: sticky;
    // top: 0;
    // z-index: 100;
    width: 100%;
    padding: 24px;
    background-color: #e3ecf8;
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    gap: 16px;

    .control {
      width: 100%;
    }
  }

  .results {
    width: 100%;
    padding: 24px;
    padding-top: 64px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    h3 {
      padding-bottom: 12px;
      @include font(18px, 600, 0.05em);
    }

    .list {
      width: 100%;
      padding-bottom: 32px;
      
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
  }
}