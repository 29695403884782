@import 'src/resources/styles';

.container {
  position: relative;

  .bar {
    width: 100%;
    padding: 24px;
    background-color: #fcf2dd;
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    gap: 16px;

    .description {
      @include font(14px, 600, 0.1em);
      color: #a26900;
    }

    .controls {
      display: flex;
      align-items: center;
      gap: 12px;

      & input {
        width: 240px;
      }

      & .button {
        height: 36px;
        padding: 0 12px;
        border: none;
        border-radius: 12px;
        background-color: #e1a83e;

        @include font(16px, 500, 0.05em);
        color: white;
        cursor: pointer;

        &:hover {
          background-color: #d79d31;
        }

        &:disabled {
          background-color: #ddd;
          color: #888;
          cursor: default;
        }
      }
    }
  }

  h3 {
    padding-bottom: 12px;
    @include font(18px, 600, 0.05em);
  }

  .results {
    width: 100%;
    padding-top: 64px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .list {
      width: 100%;
      padding-bottom: 32px;
      
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  .pagination {
    padding: 36px 0;

    .list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;

      .pageButton {
        min-width: 32px;
        height: 32px;

        @include font(14px, 500, 0);
        background-color: #f5f5f5;
        border: 1px solid #bbb;
        border-radius: 8px;
        transition: all 0.2s linear;
        cursor: pointer;

        &:hover {
          background-color: #e5e5e5;
        }

        &.current {
          background-color: #eca746;
          border-color: #d08a28;
          color: white;

          &:hover {
            background-color: #d08a28;
          }
        }
      }
    }
  }
}