@import 'src/resources/styles';

.id {
  display: block;
  padding: 4px 8px;
  margin-right: 12px;
  background: $color-accent;
  border-radius: 18px;
  @include font (14px, 500, 0);
  color: white;
}

.wrapAnnProps {
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.products {
  width: 100%;
  margin: 24px 0;
  padding: 24px 0;

  display: flex;
  flex-direction: column;
  gap: 16px;

  border-top: 1px solid #eee;

  h2 {
    margin-bottom: 16px;
    @include font (18px, 700, 0.1em);
    text-transform: uppercase;
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
  }
}