@import 'src/resources/styles';

.wrap {
  width: 100%;
  padding: 24px 0;

  display: flex;
  flex-direction: column;
  gap: 16px;

  .fields {
    width: 100%;
    padding-bottom: 16px;

    display: grid;
    grid-template-columns: 48% 48%;
    align-items: stretch;
    gap: 32px;

    & > * {
      align-self: center;
    }

    .checkbox {
      width: 100%;
      height: 100%;
      padding-bottom: 12px;

      display: flex;
      align-items: flex-end;
    }
  }

  .buttons {
    width: 100%;
    padding-right: 6px;

    display: flex;
    justify-content: flex-end;
    gap: 16px;

    .btn {
      width: auto;
      height: 28px;
      padding: 0 16px;
  
      border: none;
      border-radius: 12px;
  
      @include font (14px, 600, 0.1em);
      text-transform: uppercase;
      color: white;
  
      cursor: pointer;
    }
  
    .saveBtn {
      background: $color-success;
    }
  
    .removeBtn {
      background: $color-cancel;
    }
  }

  .error {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;

    .errorIcon {
      @include icon (20px, $color-cancel);
    }

    .errorText {
      @include font (16px, 500, 0.05em);
      line-height: 100%;
      color: $color-cancel;
    }
  }
}