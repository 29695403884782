@import 'src/resources/styles';

.group {
  width: 100%;
  padding-left: 12px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
}