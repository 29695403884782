@import 'src/resources/styles';

.wrap {
  width: 100%;
  margin: 16px 0;

  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  
  .img {
    min-width: 240px;
    width: 240px;
    min-height: 240px;
    height: 240px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 32px;

    margin-top: 16px;

    .field {
      display: flex;
      gap: 16px;

      .name {
        min-width: 120px;
        width: 120px;

        @include font(14px, 600, 0.1em);
        line-height: 140%;
        text-transform: uppercase;
        color: #ccc;
      }

      .value {
        width: 100%;

        @include font(16px, 500, 0.05em);
        line-height: 120%;
      }
    }
  }
}