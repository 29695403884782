@import 'src/resources/styles';

.wrap {
  position: relative;
  width: 100%;

  .input {
    width: 100%;
    height: 40px;
    padding: 0 52px;
  
    border: 1px solid #ddd;
    border-radius: 12px;
  
    @include font (16px, 500, 0.05em);
    color: #444;
  
    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      font-weight: 500;
      color: #ccc;
    }
  }

  .icon {
    position: absolute;
    top: 10px;
    left: 16px;

    @include icon (20px, #ccc);
  }
}


// ------------- LOADER -------------

.ldsEllipsis {
  display: inline-block;
  position: absolute;
  width: 40px;
  height: 24px;

  right: 12px;
  top: 10px;
}

.ldsEllipsis div {
  position: absolute;
  top: 8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ccc;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ldsEllipsis div:nth-child(1) {
  left: 4px;
  animation: ldsEllipsis1 0.6s infinite;
}

.ldsEllipsis div:nth-child(2) {
  left: 4px;
  animation: ldsEllipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(3) {
  left: 16px;
  animation: ldsEllipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(4) {
  left: 28px;
  animation: ldsEllipsis3 0.6s infinite;
}

@keyframes ldsEllipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ldsEllipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes ldsEllipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}