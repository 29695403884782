@import 'src/resources/styles';

.wrap {
  position: relative;
  width: 280px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  background: #f8f8f8;
  border-radius: 12px;
  border: 1px solid #eee;

  transition: all 0.3s ease;
  cursor: pointer;

  .id {
    @include style-id-mark-absolute();
    opacity: 0;
  }

  &:hover {
    background: #f2f2f2;

    .id {
      opacity: 1;
    }
  }

  .prices {
    width: 100%;
    padding-top: 12px;
    position: relative;

    display: flex;
    flex-wrap: nowrap;
    gap: 16px;

    border-top: 1px solid #ddd;

    .price, .sale, .basePrice {
      position: relative;
      width: 100%;
      padding-top: 18px;
      @include font (20px, 700, -0.05em);
      text-transform: uppercase;
      text-align: left;

      &::before {
        position: absolute;
        top: 2px;
        left: 0;

        @include font(12px, 600, 0.05em);
        text-transform: uppercase;
        color: #aaa;
      }
    }

    .price::before {
      content: "цена";
    }

    .sale::before {
      content: "со скидкой";
    }

    .basePrice:before {
      content: "рассчетная цена";
    }

    .basePriceWrap {
      position: absolute;
      left: -6px;
      top: -6px;
      bottom: -6px;
      right: -6px;
      padding: 12px;

      display: flex;
      align-items: center;
      justify-content: center;

      background: white;
      border-radius: 12px;
      opacity: 0;
      transition: all 0.1s linear;
    }

    &:hover {
      .basePriceWrap {
        opacity: 1;
      }
    }
  }

  .props {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 6px;

    .item {
      width: 100%;
      
      display: grid;
      grid-template-columns: 48% 48%;
      gap: 16px;

      .name, .value {
        @include font (14px, 600, 0.05em);
        text-align: left;
      }

      .name {
        color: #888;
      }
    }
  }
}

.wrapAnnProps {
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 24px;
}