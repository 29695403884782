@import 'src/resources/styles';

.wrap {
  position: relative;
  width: 296px;
  padding: 8px;
  padding-top: 12px;
  border-radius: 24px;
  border: 1px solid #eee;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  .lastUpdate {
    position: absolute;
    left: -6px;
    top: -6px;
    padding: 4px 8px;
    visibility: hidden;
    transition: all 0.2s linear;
    @include font(12px, 500, 0.05em);
    color: white;
    background: #e1a83e;
    border-radius: 12px;
  }

  &:hover .lastUpdate {
    visibility: visible;
  }

  .imgContainer {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    max-width: 200px;
    max-height: 200px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .name {
    @include font(16px, 600, 0.05em);
    text-align: center;
    padding: 12px 0;
  }

  .button {
    height: 32px;
    width: 100%;
    padding: 0 12px;
    border: none;
    border-radius: 12px;
    background-color: #dfab49;

    @include font(14px, 500, 0.05em);
    color: white;
    cursor: pointer;

    &:hover {
      background-color: #dd9f2d;
    }
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #eee;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include font(16px, 500, 0.05em);
  }
}