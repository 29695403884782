@import 'src/resources/styles';

.wrap {
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 2px;

  h2 {
    padding-bottom: 6px;
  }

  p {
    display: flex;
    align-items: center;
    gap: 8px;

    @include font (14px, 500, 0.05em);

    span {
      display: block;
      padding: 4px 12px;

      background: $color-success-30;
      border-radius: 12px;

      @include font (14px, 600, 0.05em);
    }
  }
}