@import 'src/resources/styles';

.wrap {
  width: 100%;
  flex-direction: column;
  gap: 0;

  .info {
    width: 100%;
    padding: 16px 24px;
    position: relative;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;

    border: 1px solid #ddd;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    background: #efefef;
    transition: all 0.3s ease;
    cursor: pointer;

    .icon {
      margin-right: 60px;
      @include icon (36px, #ccc);
    }

    .prop {
      @include font (12px, 600, 0.05em);
      text-align: left;

      &:nth-child(odd) {
        padding-right: 140px;
      }
      
      .name {
        color: #999;
        text-transform: uppercase;
      }

      .value {
        font-size: 24px;
        font-weight: 700;
        line-height: 130%;
      }
    }

    .buttons {
      position: absolute;
      right: 12px;
      top: 12px;

      display: flex;
      align-items: center;
      gap: 12px;

      opacity: 0;
      transition: all 0.3s ease;
    }

    &:hover {
      background: #e8e8e8;

      .buttons {
        opacity: 1;
      }
    }
  }

  .units {
    width: 100%;
    padding: 12px 24px;

    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: 16px;

    border: 1px solid #ddd;
    border-top: none;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    background: #f8f8f8;

    .unit {
      width: 290px;
      padding: 8px 16px;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;

      background: white;
      border: 1px solid #eee;
      border-radius: 12px;

      .prop {
        @include font (18px, 700, 0.05em);
        text-align: left;

        &:first-child {
          padding-right: 32px;

          .name {
            display: none;
          }

          .value {
            font-size: 24px;
            color: $color-accent;
          }
        }

        .name {
          font-size: 12px;
          font-weight: 500;
          color: #aaa;
        }
  
        .value {
          color: #444;
        }
      }
    }
  }
}