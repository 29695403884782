@import 'src/resources/styles';

.wrap {
  width: 100%;
  margin: 16px 0;

  display: flex;
  flex-direction: column;
  gap: 48px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 32px;

    margin-top: 16px;

    .field {
      display: flex;
      gap: 16px;

      .name {
        min-width: 210px;
        width: 210px;

        @include font(14px, 600, 0.1em);
        line-height: 140%;
        text-transform: uppercase;
        color: #ccc;
      }

      .value {
        width: 100%;

        @include font(16px, 500, 0.05em);
        line-height: 120%;

        .icon {
          @include icon (20px, #aaa);
        }
      }
    }
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;

    .img {
      min-width: 180px;
      width: 180px;
      min-height: 180px;
      height: 180px;
  
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}