@import 'src/resources/styles';

.wrap {
  width: 100%;
  padding: 24px 0;

  display: flex;
  flex-direction: column;
  gap: 2px;

  border-top: 1px solid #eee;

  h2 {
    margin-bottom: 32px;
    @include font (18px, 700, 0.1em);
    text-transform: uppercase;
  }

  .items {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  .addBtn {
    width: 100%;
    min-height: 70px;

    border-radius: 12px;
    border: 2px dashed rgba($color-accent, 0.5);
    background: rgba($color-accent, 0.1);

    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      background: rgba($color-accent, 0.2);
    }

    .icon {
      @include icon (44px, rgba($color-accent, 0.5))
    }

  }
}