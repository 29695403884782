@import 'src/resources/styles';

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.outlet {
  width: 100%;
  padding-left: 60px;

  .refreshScreen {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;

    background: #f8f8f8;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    .icon {
      @include icon (120px, rgba($color-accent, 0.8));
      animation: rotate 2s linear infinite both;
    }

    .text {
      display: inline-block;
      max-width: 400px;

      @include font (16px, 600, 0.1em);
      text-transform: uppercase;
      line-height: 120%;
      color: $color-accent;
      text-align: center;
    }
  }

  .header {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 50;

    height: 100vh;
    padding: 40px 20px;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    
    background: #f9f9f9;
    border-right: 1px solid #f5f5f5;

    .top, .bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }

    .btnHome, 
    .btnGoBack, 
    .btnUpdate, 
    .btnGallery, 
    .btnSearch,
    .btnPricesUpdated {
      width: 40px;
      height: 40px;
      margin: 0;
      padding: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;
      border: none;

      cursor: pointer;

      & svg {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
      }
    }

    .btnHome {
      background: $color-accent;

      & svg path {
        fill: white;
      }
    }

    .btnGallery {
      padding-top: 2px;
      background: $color-success;

      & svg path {
        fill: white;
      }
    } 

    .btnSearch {
      background: $color-accent-2;

      & svg path {
        fill: white;
      }
    }

    .btnPricesUpdated {
      background: $color-accent-3;

      & svg path {
        fill: white;
      }
    }

    .btnGoBack, .btnUpdate {
      background: white;
      border: 1px solid #ccc;
    }
  }

  .wrap {
    padding-top: 45px;
    padding-left: 100px;
    padding-right: 40px;
    padding-bottom: 30px;

    h1 {
      display: flex;
      align-items: center;
      
      @include font (24px, 700, 0.05em);
      text-transform: uppercase;
      color: #333;
    }

    h2 {
      display: flex;
      align-items: center;
      
      @include font (18px, 700, 0.05em);
      text-transform: uppercase;
      color: #333;
    }
  }
}