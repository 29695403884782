@import 'src/resources/styles';

.wrap {
  width: 300px;
  padding: 8px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  border: 1px solid #e5e5e5;
  border-radius: 12px;
  text-decoration: none;

  .id {
    @include style-id-mark-absolute();
    opacity: 0;
  }

  &:hover {
    background: #f2f2f2;

    .id {
      opacity: 1;
    }
  }

  .img {
    width: 180px;
    height: 100px;
    min-width: 180px;
    min-height: 100px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .content {
    width: 100%;
    padding-top: 16px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .title {
      width: 100%;
      @include font (14px, 700, 0.05em);
      line-height: 120%;
      text-transform: uppercase;
      text-align: center;
    }
  }
}