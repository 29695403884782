@import 'src/resources/styles';

.wrap {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .loading {
    @include font (16px, 400, 0.05em);
    color: $color-accent;
  }

  .list {
    padding-top: 32px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 16px;

    .imgWrap {
      position: relative;
      height: 220px;

      img {
        height: 220px;
        border-radius: 6px;
      }

      .id {
        @include style-id-mark-absolute();
        opacity: 0;
      }

      &:hover .id {
        opacity: 1;
      }

      .amountProducts {
        position: absolute;
        right: -8px;
        bottom: -8px;
        padding: 3px 8px;

        display: flex;
        align-items: center;
        gap: 8px;

        background: $color-success;
        border-radius: 12px;
        border: 3px solid white;

        @include font (14px, 600, 0);
        color: white;

        .icon {
          @include icon (20px, white);
        }

        &.notProducts {
          background: $color-cancel;
        }
      }
    }
  }
}