@import 'src/resources/styles';

.wrap {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(black, 0.6);

  .window {
    max-width: 900px;
    width: 100%;
    max-height: 95vh;
    padding: 24px;

    display: flex;
    flex-direction: column;
    gap: 24px;

    background: white;
    border-radius: 12px;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.1);

    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
    }
    
    &::-webkit-scrollbar-track {
      margin: 16px 0;
      background-color: transparent;
      border-radius: 100px;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: #bbb;
      border-radius: 100px;
    }

    .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        margin: 0;
        @include font (18px, 700, 0.05em);
        text-transform: uppercase;
      }
    }
  }
}