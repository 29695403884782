@import 'src/resources/styles';

.wrap {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .loading {
    @include font (16px, 400, 0.05em);
    color: $color-accent;
  }

  .id {
    @include style-id-mark-in-text();
  }

  .content {
    padding-top: 32px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    gap: 70px;
    
    .imgWrap {
      position: sticky;
      top: 40px;
      align-self: baseline;

      img {
        max-width: 70vw;
        max-height: 70vh;
        border-radius: 4px;
      }

      .mark {
        position: absolute;
        width: 24px;
        height: 24px;
        background: $color-success;
        transform: translate(-50%, -50%);

        &, & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
          @include font (16px, 700, 0);
        }

        & > div {
          width: 18px;
          height: 18px;
          background: white;
        }
      }

      .annotation {
        position: absolute;
        left: 12px;
        bottom: -24px;
        @include font (14px, 400, 0.05em);
        font-style: italic;
        color: #aaa;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 24px;

      .products {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 12px;

        h2 {
          padding-bottom: 6px;
        }

        .notProducts {
          padding: 8px 24px;
          display: flex;
          align-items: center;
          gap: 16px;
      
          background: #eee;
          border-radius: 12px;
      
          .icon {
            @include icon (20px, #777);
          }
      
          p {
            @include font (14px, 600, 0.05em);
            color: #777;
          }
        }
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
    list-style-type: none;

    .item {
      position: relative;
      min-height: 120px;
      padding: 12px 16px;
      display: grid;
      grid-template-columns: 100px 1fr;
      gap: 12px;
      border: 1px dashed #ccc;
      border-radius: 16px;

      .number {
        position: absolute;
        top: -4px;
        left: -4px;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-success;
        border-radius: 6px;
        @include font (18px, 700, 0);
        color: white;
      } 

      .pos {
        @include style-id-mark-absolute ();
        left: auto;
        right: 12px;
        opacity: 0;
      }

      img {
        align-self: center;
        justify-self: center;
        max-width: 100px;
        max-height: 100px;
      }

      .description {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .path {
          display: block;
          width: fit-content;
          padding: 4px 12px;
          background: rgba($color-success, 0.2);
          border-radius: 12px;
          @include font (14px, 600, 0.05em);
        }

        .name {
          padding-left: 4px;
          @include font (16px, 600, 0.05em);
          line-height: 110%;
          text-transform: uppercase;
        }
      }

      .btn, .btnAdd {
        position: absolute;
        bottom: 12px;
        right: 12px;
        opacity: 0;
        transition: all 0.3s linear;
      }

      .btnAdd {
        opacity: 1;
      }

      &:hover {
        .btn, .pos {
          opacity: 1;
        }
      }
    }
  }
}