@import 'src/resources/styles';

.wrap {
  position: relative;
  width: 100%;
  padding: 24px 0;

  display: flex;
  flex-direction: column;
  gap: 2px;

  border-top: 1px solid #eee;

  h2 {
    margin-bottom: 16px;
    @include font (18px, 700, 0.1em);
    text-transform: uppercase;
  }

  .findBtn {
    position: absolute;
    right: 12px;
    top: 18px;

    .findIcon {
      @include icon (18px, white);
    }
  }

  .item {
    width: 100%;
    padding: 8px 12px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    border-radius: 12px;
    transition: all 0.3s ease;
    cursor: pointer;

    .removeBtn {
      opacity: 0;
      transition: all 0.3s ease;
    }
    
    &:hover {
      background: #f9f9f9;

      .removeBtn {
        opacity: 1;
      }
    }

    .content {
      width: 70%;
      display: grid;
      grid-template-columns: 50% 40% 40px;
      gap: 16px;
  
      .name {
        width: 100%;
        @include font (16px, 600, 0.05em);
        line-height: 120%;
        color: #bbb;
      }

      .value, .measureUnit {
        width: 100%;
        text-align: left;
        @include font (16px, 500, 0.05em);
        line-height: 120%;
      }
    }
  }

  .notItems {
    width: 100%;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      @include icon (32px, #ddd);
    }
  }
}

.form {
  width: 100%;
  display: grid;
  align-items: stretch;
  grid-template-columns: 3fr 2fr 1fr;
  column-gap: 12px;
}

.submitBtn {
  height: 44px;
}